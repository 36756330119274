import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { composeStyles, Link, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { useEventListener } from 'usehooks-ts';

export interface GrowingElementProps
  extends PropsWithClassProps<'main' | 'topContainer' | 'topElement' | 'bottomContainer' | 'bottomElement'> {
  link?: string;
  showInitially?: boolean;
  lastScroll?: number;
  topSlot?: React.ReactNode;
  bottomSlot?: React.ReactNode;
  onInViewChange?: (inView: boolean) => void;
}

export const GrowingElement = ({
  link,
  topSlot = null,
  bottomSlot = null,
  showInitially,
  lastScroll,
  onInViewChange,
  classProps,
}: GrowingElementProps) => {
  const { ref: inViewRef, inView } = useInView({
    rootMargin: '50px 1600px',
  });

  const [screenPosition, setScreenPosition] = useState('left');

  const itemVisible = useMemo(() => inView || showInitially, [inView, showInitially]);

  const domElement = useRef(null);

  const calculateScreenPosition = () => {
    if (domElement?.current && window) {
      const screenWidth = window.innerWidth;
      const elementBox = domElement.current.getBoundingClientRect();
      if (elementBox.left > screenWidth) return setScreenPosition('right');
      const leftPercentage = ((elementBox.left + elementBox.width / 2) / screenWidth) * 100;
      if (leftPercentage < 33) {
        setScreenPosition('left');
      } else if (leftPercentage > 66) {
        setScreenPosition('right');
      } else {
        setScreenPosition('center');
      }
    }
  };

  useEffect(() => {
    if (!domElement || !window) return;
    calculateScreenPosition();
  }, [lastScroll, itemVisible]);

  useEffect(() => {
    if (onInViewChange) {
      onInViewChange(inView);
    }
  }, [inView, onInViewChange]);

  useEventListener('resize', calculateScreenPosition);

  const screenPositionStyle = {
    left: 'canhover:sm:group-hover:-right-10',
    center: 'canhover:sm:group-hover:-left-5 canhover:sm:group-hover:-right-5',
    right: 'canhover:sm:group-hover:-left-10',
  };

  const defaultStyles: GrowingElementProps['classProps'] = {
    root: 'group relative min-w-72 min-h-64 md:min-h-0 w-full grid group h-full canhover:hover:z-30',
    main: '',
    topContainer: 'relative aspect-video w-full',
    topElement: twMerge(
      'absolute inset-0 transition-all duration-200 canhover:group-hover:-top-4',
      screenPositionStyle[screenPosition || 'center'],
    ),
    bottomContainer: 'relative h-18 md:h-20',
    bottomElement: twMerge(
      'absolute inset-0 overflow-hidden rounded-b-lg bg-gray-800/0 transition-all duration-150 canhover:group-hover:-bottom-24 canhover:group-hover:bg-gray-800/100',
      screenPositionStyle[screenPosition || 'center'],
    ),
  };

  const styles = composeStyles(defaultStyles, classProps);

  return (
    <Link
      href={link}
      noLink={!link}
      classProps={{
        root: styles.root,
      }}
      ref={inViewRef}
    >
      {itemVisible && (
        <div
          ref={domElement}
          className={styles.main}
          style={{ willChange: 'left, right', containIntrinsicSize: '242px' }}
        >
          <div className={styles.topContainer}>
            <div className={styles.topElement} style={{ willChange: 'top' }}>
              {topSlot}
            </div>
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.bottomElement} style={{ willChange: 'bottom' }}>
              {bottomSlot}
            </div>
          </div>
        </div>
      )}
    </Link>
  );
};
