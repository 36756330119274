import React from 'react';
import { ImageCard } from '@components/molecules/ImageCard/ImageCard';
import { Slider } from '@components/molecules/Slider/Slider';

export default function El(Element) {
  const slides = Element?.gallery?.items?.map((e) => {
    e.image = e.asset;
    return e;
  });
  return (
    <div className="md:px-16 lg:px-32">
      <Slider
        buttonsOverlaying
        classProps={{
          slideContainer: 'rounded-lg',
          root: '',
          buttonLeft: 'left-6 lg:-left-14',
          buttonRight: 'right-6 lg:-right-14',
        }}
      >
        {slides.map((slide, index) => (
          <>
            {slide?.image?.url && (
              <ImageCard
                classProps={{ ImageCard: 'relative w-full aspect-video', root: 'flex-none' }}
                key={index}
                imageSrc={slide.image.url}
                imageAlt={slide.image.alt}
                description={slide.image.caption}
                copyright={slide.image.copyright_text}
                sizes="80vw"
              />
            )}
          </>
        ))}
      </Slider>
    </div>
  );
}
